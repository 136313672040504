
// Import TinyMCE
import tinymce from 'tinymce/tinymce'

import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';

// plugins
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
//import 'tinymce/plugins/'

import 'tinymce-i18n/langs/cs_CZ'

//tinymce.init({
//    selector: 'textarea',  // change this value according to your HTML
//    license_key: 'gpl'
//});

tinymce.init({
    selector: '.tinymce textarea',
    language: 'cs_CZ',
    plugins: ['table', 'link', 'anchor', 'image', 'lists', 'code'],
    file_picker_types: 'image',
    block_unsupported_drop: true,
    images_upload_url: '/admin/tinymce/upload',
    automatic_uploads: true,

    //a11y_advanced_options: true,
});